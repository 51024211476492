import React from "react"
import { Link } from "gatsby"

const IndieLink = ({
  text,
  marginSize,
  url,
  externalLink,
  width = "12rem",
  ...props
}) => {
  return externalLink ? (
    <a
      href={url}
      className="button learn-more"
      target="blank"
      rel="noopener noreferrer"
      style={{ width: width }}
      {...props}
    >
      <span className="circle" aria-hidden="true">
        <span className="icon arrow"></span>
      </span>
      <span className="button-text">{text}</span>
    </a>
  ) : (
    <Link
      to={url}
      className="button learn-more"
      style={{ width: width }}
      {...props}
    >
      <span className="circle" aria-hidden="true">
        <span className="icon arrow"></span>
      </span>
      <span className="button-text">{text}</span>
    </Link>
  )
}

export default IndieLink
