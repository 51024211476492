import React from "react"
import IndieLink from "../components/IndieLink"
import { StaticImage } from "gatsby-plugin-image"
const NotFoundPage = () => {
  return (
    <>
      <div
        className="has-background-black-bis"
        style={{
          padding: "0px",
          position: "relative",
          minHeight: "100vh",
        }}
      >
        <div style={{ display: "grid" }}>
          <div
            style={{
              zIndex: "1",
              gridArea: "1/1",
              display: "grid",
            }}
          >
            <StaticImage
              className="m4"
              style={{
                gridArea: "1/1",
              }}
              layout="fullWidth"
              alt=""
              src="../assets/404.png"
            />
          </div>
          <div
            className="text-wrapper"
            style={{
              zIndex: "2",
              gridArea: "1/1",
              position: "relative",
              display: "grid",
            }}
          >
            <div>
              <h1
                className="is-size-1 is-size-1-mobile"
                style={{
                  fontFamily: "nimbus-sans",
                  fontWeight: "900",

                  color: "black",
                }}
              >
                404
              </h1>
              <div
                className="is-size-1 is-size-1-mobile is-white"
                style={{
                  fontFamily: "nimbus-sans",
                  fontWeight: "900",
                  color: "white",
                }}
              >
                Don't Panic!
              </div>
              <h3
                className="content-padding is-size-2 is-size-2-mobile is-white"
                style={{
                  fontFamily: "nimbus-sans",
                  fontWeight: "900",
                  color: "white",
                }}
              >
                Let's get you back
                <br />
                on track
              </h3>
              <div className="content-padding">
                <IndieLink text="Homepage" url="/" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
